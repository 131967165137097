import { Button, Pane, TextInput } from "evergreen-ui";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import LoadingContext from "../../contexts/Loading";
import UserContext from "../../contexts/User";
import { createUser, login, signInWithGoogle } from "../../utils/helpers";
import {Helmet} from "react-helmet";

function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const params = useSearchParams()
  const inIframe = window.self !== window.top;

  const { authed } = useContext(UserContext);
  const { setIsLoading } = useContext(LoadingContext);

  useEffect(() => {
    if (params.id) {
      setEmail(params.id)
    }
  }, [params])
  useEffect(() => {
    console.log("login useeffect", authed);
    if (authed) {
      console.log("navigate");

      navigate("/catalog");
    }
  }, [authed, navigate]);

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
       
      }}
    >
      <Helmet>
        <title>Register</title>
      </Helmet>
      <div style={{ paddingTop: 100, textAlign: "center" }}>
        <h1 style={{fontSize: 60, marginBottom: 0}}>FIND YOUR FIT</h1>
        {/* <p style={{fontFamily: "DM Sans", textTransform: "uppercase"}}>Find the The style that suits you, suits your confidence</p> */}
        <TextInput
            placeholder="Email"
            type="email"
            value={email}
            style={{fontFamily: "DM Sans", width: 300}}
            onChange={(e) => setEmail(e.target.value)}

          />
          <br/>
          <TextInput
            placeholder="Password"
            type="password"
            style={{ marginTop: 10, fontFamily: "DM Sans", width: 300 }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <br/>
          <Button
            appearance="primary"
            marginTop={10}
            width={"95%"}
            style={{fontFamily: "DM Sans", width: 300}}
            onClick={() => createUser(email, password)}
          >
            Register
          </Button>
          <br/>
          <hr style={{ borderTop: "1px solid gray", width: 300 }} />

{!inIframe && <Button
  appearance="default"
  marginTop={10}
  onClick={() => {
    signInWithGoogle().then(() => navigate("/catalog"));
  }}
  style={{fontFamily: "DM Sans", width: 300}}

>
  Continue with Google
</Button>}
      </div>
    </div>
  );
}

export default Login;
