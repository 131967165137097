import {
  Button,
  Card,
  Checkbox,
  Dialog,
  FileCard,
  FileUploader,
  Heading,
  Pane,
  Paragraph,
  RadioGroup,
  SideSheet,
  Tab,
  Tablist,
} from "evergreen-ui";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingContext from "../../contexts/Loading";
import UserContext from "../../contexts/User";
import { fetchGet, handleUpload, idGenerator } from "../../utils/helpers";
import "./Setup.css";
import { collection, doc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { db } from "../../utils/firebase";
import good_photo_example from "../../assets/good_example_cropped.png"
import bad_photo_example from "../../assets/bad_example_cropped2.png"
import { Helmet } from "react-helmet";

export default function Setup(props) {
  const { user, authed, updateUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [step, setStep] = useState(1);
  const [preferSex, setPreferSex] = useState(null);
  const [fileRejections, setFileRejections] = useState([]);
  const handleChange = useCallback((files) => setFiles([files[0]]), []);
  const handleRejected = useCallback(
    (fileRejections) => setFileRejections([fileRejections[0]]),
    []
  );
  const handleRemove = useCallback(() => {
    setFiles([]);
    setFileRejections([]);
  }, []);
  const { setIsLoading } = useContext(LoadingContext);
  const [allPoints, setAllPoints] = useState([])
  const [displayedPoints, setDisplayedPoints] = useState({})
  const [currentPoint, setCurrentPoint] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  const [exampleOpen, setExampleOpen] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const imageRef = useRef();
  const [realPoints, setRealPoints] = useState({});
  const [imageName, setImageName] = useState("");
  const [showInstructions, setShowInstructions] = useState(true)
  const [checked, setChecked] = useState(false)
  const imageClick = (e) => {
    const offset = imageRef.current.getBoundingClientRect();

    const x = e.pageX;
    const y = e.pageY;
    console.log("click", x, y);
    let newPoints = [...(displayedPoints[currentPoint.name] === undefined ? [] : displayedPoints[currentPoint.name])];
    newPoints.push([x, y]);
    newPoints = newPoints.slice(-1 * currentPoint.numPoints);
    setDisplayedPoints(prev => ({...prev, [currentPoint.name]: [...newPoints]}))
    const distanceToTop = window.pageYOffset + offset.top;

    setRealPoints({
      ...realPoints,
      [currentPoint.name]: newPoints.map((point) => {
        const realX = (point[0] - offset.left) / offset.width;
        const realY = (point[1] - distanceToTop) / offset.height;
        return { x: realX, y: realY };
      }),
    });
  };
  const dotWidth = 25;
  const dotHeight = 25;

  useEffect(() => {
    const fetchPoints = async () => {
      const productsRef = collection(db, "points");
      const q = query(productsRef, where("visible", "==", true));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.docs.length > 0) {
        const data = querySnapshot.docs.map((doc) => doc.data());
        data.sort((a, b) => a.order - b.order)
        console.log("points",data)
        setAllPoints(data);
        setCurrentPoint(data[0])
      }
    }
    fetchPoints()
  }, [])

  useEffect(() => {
    if (displayedPoints[currentPoint.name] === undefined) {
      return;
    }
    displayedPoints[currentPoint.name].forEach((point, index) => {
      const div = document.getElementById(`${currentPoint.name}-${index}`);
      console.log(`${currentPoint.name}-${index}`, div);
      div.style.position = "absolute";
      div.style.display = "block";
      const divX = point[0] - dotWidth / 2;
      const divY = point[1] - dotHeight / 2;
      div.style.left = divX + "px";
      div.style.top = divY + "px";
      div.style.zIndex = 100000;
    });
  }, [displayedPoints, currentPoint]);

  useEffect(() => {
    const pointData = allPoints.filter(point => point.name === currentPoint.name);
    if (pointData.length > 0) {
      setSelectedIndex(pointData[0].order)
    }
  }, [currentPoint, allPoints]);

  console.log("currentpoint", currentPoint);


  const notifyUpload = async (template_name) => {
      return fetchGet(
        `/notify_upload/${template_name}`,
      )
  }

  const upload = async () => {
    if (fileRejections.length !== 0) {
      alert("Unable to upload photo, please fix the errors first!");
      return;
    }
    if (preferSex === null) {
      alert("Please select a preference!");
      return;
    }
    const imagePath = idGenerator();
    setIsLoading(true);
    if (files.length > 0) {
      const res = await handleUpload(
        files[0],
        `template/${user.uid}`,
        imagePath
      );
      console.log("res", res);
      setImageUrl(res);
      setImageName(imagePath);
      setIsLoading(false);
      const data = {
        name: imagePath,
        user: user.uid,
        url: res,
        traced: false,
      };
      await setDoc(doc(db, "templates", imagePath), {...data, submittedAt: new Date()} );
      // user.clothing_preference = preferSex;
      console.log("setup user", user)
      await updateDoc(doc(db, "users", user.uid), {
        clothing_preference: preferSex,
        processed: false,
      });
      // await notifyUpload(imagePath)
      await updateUser()
      navigate("/catalog?state=complete");

    } else {
      alert("No files found!");
    }
  };
  const submit = async () => {
    const hasAllData = allPoints.every(point => realPoints[point.name].length === point.numPoints)
    if (
      !hasAllData
    ) {
      alert("Missing point data");
      return;
    } else {
      try {
        console.log("uploading")
        const data = {
          name: imageName,
          user: user.uid,
          url: imageUrl,
          traced: true, 
          processed: false,
        };
        for (let i = 0; i < allPoints.length; i++) {
          const point = allPoints[i]
          data[point.name] = realPoints[point.name]
        }
        await setDoc(doc(db, "templates", imageName), {...data, submittedAt: new Date(),} );
        await updateUser()
        navigate("/catalog?state=complete");
      } catch (err) {
        alert(err);
        alert("Failed to upload data points");
      }
    }
  };
  let body = null;
  if (!authed) {
    return;
  }
  if (step === 1) {
    body = (
      <div style={{fontFamily: "DM Sans !important"}}>
        {" "}
        <Dialog 
        hasFooter={false}
        isShown={showInstructions} title="Instructions" onCloseComplete={() => setShowInstructions(false)}
        style={{fontFamily: "DM Sans"}}
        >
          <div style={{fontFamily: "DM Sans"}}>
            Please upload a photo that meets these requirements:
          <ul style={{listStyleType: "disc", listStylePosition: "inside", display: 'grid'}}>
            <li>
            <b>Pose:</b> Stand straight with hands by your sides.
            </li>
            <li>
            <b>Clothing:</b> Wear a tight top (short-sleeve tee or tank top). Tuck in any extra fabric.
            </li>
            <li>
            <b>Framing:</b> Make sure your whole body is in the picture, stand in the center.
            </li>
            <li>
            <b>Lighting:</b> Ensure good lighting with almost no shadows.
            </li>
          </ul>
          <br/>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <div style={{ textAlign: 'center' }}>
              <p>Good Photo:</p>
              <img src={good_photo_example} style={{ width: '100%', marginTop: 10 }} alt="Good Example" />
            </div>
            <div style={{ textAlign: 'center' }}>
              <p>Bad Photo:</p>
              <img src={bad_photo_example} style={{ width: '100%', marginTop: 10 }} alt="Bad Example" />
            </div>
          </div>
          </div>
          </Dialog>
          <Button style={{marginBottom: 20}} onClick={() => setShowInstructions(true)}>View Instructions</Button>
        <FileUploader
          acceptedMimeTypes={["image/jpeg", "image/png", "image/heic"]}
          label="Upload File"
          description="You can upload 1 file. File can be up to 50 MB."
          maxSizeInBytes={50 * 1024 ** 2}
          maxFiles={1}
          onChange={handleChange}
          onRejected={handleRejected}
          renderFile={(file) => {
            const { name, size, type } = file;
            const fileRejection = fileRejections.find(
              (fileRejection) => fileRejection.file === file
            );
            const { message } = fileRejection || {};
            console.log("file", file);
            const imageUrl = URL.createObjectURL(file);
            return (
              <>
                <img
                  alt="file upload"
                  src={imageUrl}
                  style={{ width: "100%" }}
                />
                <FileCard
                  key={name}
                  isInvalid={fileRejection != null}
                  name={name}
                  onRemove={handleRemove}
                  sizeInBytes={size}
                  type={type}
                  validationMessage={message}
                />
              </>
            );
          }}
          values={files}
        />
        <Pane>
        What type of clothes are you interested in?
        <RadioGroup
      size={16}
      value={preferSex}
      isRequired
      options={[
        { label: null, value: null },
        { label: 'Male', value: 'male' },
      { label: 'Female', value: 'female' },
      { label: 'Both', value: 'both' }]}
      onChange={event => setPreferSex(event.target.value)}
    />
        </Pane>
        <Checkbox
        style={{marginTop: 50}}
      label="I have read and agree to the Terms of Service and Privacy Policy"
      checked={checked}
      onChange={e => setChecked(e.target.checked)}
    />
        <Button
          appearance="primary"
          style={{ float: "right" }}
          onClick={upload}
          disabled={files.length === 0 || !checked || !preferSex}
        >
          Upload
        </Button>
      </div>
    );
  } else if (step === 2) {
    let tabBody;
    let heading = "";
    heading = <>{allPoints[selectedIndex].heading}</>;
    tabBody = (
      <>
        <div size={500} style={{ width: "100%", flexBasis: "100%" }}>
          {`Click on the point(s) of your `}<b>{allPoints[selectedIndex].name}</b>
        </div>
        <hr style={{ width: "100%" }} />
        <img
          src={
            allPoints[selectedIndex].helpingImage
          }
          style={{ width: "100%" }}
        />
      </>
    );
    body = (
      <>
      {allPoints.map(point => {
        return [...Array(point.numPoints).keys()].map(index => <div key={`${point.name}-${index}`} class="dot" id={`${point.name}-${index}`}></div>)

      })}
        <>
          <SideSheet
            isShown={exampleOpen}
            onCloseComplete={() => setExampleOpen(false)}
            containerProps={{
              display: "flex",
              flex: "1",
              flexDirection: "column",
            }}
          >
            <Pane
              zIndex={1}
              flexShrink={0}
              elevation={0}
              backgroundColor="white"
            >
              <Pane padding={16} borderBottom="muted">
                <Heading size={600}>Configure your template</Heading>
                <Paragraph size={400} color="muted">
                  Let us know where your neck, shoulders, and hips are in the
                  image to help us process your image
                </Paragraph>
              </Pane>
              <Pane display="flex" padding={8}>
                <Tablist>
                  {["Neck", "Shoulders", "Hips", "Top", "Bottom"].map(
                    (tab, index) => {
                      return (
                        <Tab
                          key={tab}
                          isSelected={selectedIndex === index}
                          onSelect={() => setSelectedIndex(index)}
                        >
                          {tab}
                        </Tab>
                      );
                    }
                  )}
                </Tablist>
              </Pane>
            </Pane>
            <Pane flex="1" overflowY="scroll" background="tint1" padding={16}>
              <Card backgroundColor="white" elevation={0} height={500}>
                {tabBody}
              </Card>
            </Pane>
          </SideSheet>
          <Button
            appearance="default"
            onClick={() => setExampleOpen(true)}
            style={{ float: "right", marginBottom: 15 }}
          >
            View Instructions
          </Button>
        </>
        <Paragraph>{heading}</Paragraph>
        <img
          ref={imageRef}
          onClick={imageClick}
          alt="file upload"
          src={imageUrl}
          style={{ width: "100%" }}
        />
        <Button
          appearance="primary"
          onClick={() => {
            const index = allPoints.findIndex(point => point.name === currentPoint.name)
            console.log("CLICKED SUBMIT", index, allPoints.length)
            if (index === allPoints.length - 1) {
              console.log("submitting")
              submit()
              return
            } else {
              console.log("incrementing point")
              setCurrentPoint(allPoints[index + 1])
            }

          }}
          style={{ float: "right", marginBottom: 15 }}
          disabled={
            displayedPoints[currentPoint.name] === undefined || displayedPoints[currentPoint.name] < currentPoint.numPoints
          }
        >
          Confirm
        </Button>
      </>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        minHeight: "100vh",
       
      }}
    >
      <Helmet>
        <title>Setup</title>
      </Helmet>
      <div style={{ paddingTop: 100 }}>
        <Pane
          maxWidth={"90vw"}
          width={800}
          background="tint2"
          border="muted"
          elevation={1}
          padding={40}
          style={{ margin: "0 auto" }}
        >
          {body}
        </Pane>
      </div>
    </div>
  );
}
