import "./Home.css";
import { Button, Dialog, Heading, Pane, TextInput } from "evergreen-ui";
import bg from "../../assets/bg.jpg";
import hanger from "../../assets/hanger.jpg";
import image1 from "../../assets/image-1.png";
import image2 from "../../assets/image-2.png";
import image3 from "../../assets/image-3.png";
import image4 from "../../assets/image-4.png";
import { useNavigate } from "react-router-dom";

import { useContext, useEffect, useState } from "react";
import { doc, setDoc } from "firebase/firestore";
import { db, analytics } from "../../utils/firebase";
import { logEvent } from "firebase/analytics";
import UserContext from "../../contexts/User";
import toast from "react-hot-toast";
function Home() {
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const isMobile = window.innerWidth < 768;

  const { authed } = useContext(UserContext);
  useEffect(() => {
    if (authed) {
      navigate("/catalog");
    }
  }, [authed, navigate]);
  const handleSubmit = async () => {
    await setDoc(doc(db, "waitlist", email.toLowerCase()), {
      name: name,
      email: email.toLowerCase(),
      submittedAt: new Date(),
      processed: false,
    });
    setShowDialog(false);
    toast.success("Joined waiting list");
  };

  const tryNowButtonClick = () => {
    logEvent(analytics, "try_now_initiated");

    navigate("/register");
  };

  return (
    <div
      className="App"
      style={{
        marginTop: 20,
        textAlign: "left",
        width: "80vw",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Dialog
        isShown={showDialog}
        title={"Join Waitlist"}
        confirmLabel="Join"
        hasFooter={false}
        onCloseComplete={() => setShowDialog(false)}
      >
        <TextInput
          width={"100%"}
          placeholder="Name"
          style={{ marginBottom: 10, fontFamily: "DM Sans" }}
          onChange={(e) => setName(e.target.value)}
        />
        <br />

        <TextInput
          width={"100%"}
          placeholder="Email"
          type="email"
          style={{ marginBottom: 10, fontFamily: "DM Sans" }}
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <Button
          style={{ marginBottom: 10, float: "right", fontFamily: "DM Sans" }}
          onClick={handleSubmit}
        >
          Join
        </Button>
      </Dialog>
      <Pane>
        <img
          src={image1}
          style={{
            width: isMobile ? "100vw" : "40%",
            marginTop: 20,
            marginBottom: 2,
            float: "right",
          }}
        />

        <h1 style={{ fontSize: 100, marginBottom: 0 }}>SEE YOURSELF</h1>
        <h1
          style={{
            color: "#858585",
            fontSize: 100,
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          IN EVERY LOOK
        </h1>

        <p style={{ fontFamily: "DM Sans", fontWeight: "bold", fontSize: 20 }}>Step into your next favorite outfit</p>
        <p style={{ fontFamily: "DM Sans", fontSize: 20 }}>Try on clothes virtually</p>
        <Button
          style={{
            width: isMobile ? "100%" : 300,
            fontSize: 20,
            color: "white",
            backgroundColor: "black",
            height: 50,
            fontFamily: "DM Sans",
          }}

          onClick={tryNowButtonClick}
        >
          Join Now
        </Button>
      </Pane>
          <div style={{display: isMobile ? "block" : "flex", flexDirection: "row", width: "100%", gap: 50}}>
      <img
        src={image2}
        style={{ width: isMobile ? "100%" : "40%", marginTop: 20, marginBottom: 2}}
      />
      <h2 style={{ fontFamily: "DM Sans", alignSelf: "center" }}>
        Find your next outfit with Versipel. <br />{" "}
        <br/>
          <div style={{ color: "#858585", fontSize: 20 }}>Explore clothing from popular brands, tailored to your taste, powered by the latest AI innovation.</div>
      </h2>
      </div>
      <div style={{display: isMobile ? "block" : "flex", flexDirection: "row", width: "100%", gap: 50}}>
        {isMobile && <img
        src={image3}
        style={{ width:  "100%", marginTop: 20, marginBottom: 2}}
      />}
      <h2 style={{ fontFamily: "DM Sans", alignSelf: "center" }}>
      Try clothes on virtually, and buy your new favourite look!
<br/><br/>
      <div style={{ color: "#858585", fontSize: 20 }}>Discover how technology meets fashion - personalized, seamless, and instant.</div>

        </h2>
      {!isMobile && <img
        src={image3}
        style={{ width: "40%", marginTop: 20, marginBottom: 2}}
      />}
      </div>
      <div style={{display: isMobile ? "block" : "flex", flexDirection: "row", width: "100%", gap: 50, alignItems: "center"}}>
      <img
        src={image4}
        style={{ width: isMobile ? "100%" : "40%"}}
      />
      <div>
      <Button
          style={{
            width: isMobile ? "100%" : 300,
            fontSize: 20,
            color: "white",
            backgroundColor: "black",
            height: 50,
            fontFamily: "DM Sans",
          }}
          onClick={tryNowButtonClick}
        >
          Try Now
        </Button>
      </div>
       
</div>
      <hr style={{ marginTop: 20, marginBottom: 20, width: "100%" }} />
      <h3 style={{ fontFamily: "DM Sans" }}>Your privacy is our priority </h3>
      <p style={{ fontFamily: "DM Sans" }}>
        Your photos are strictly yours. We will never misuse, sell, or share
        them with third parties. They are used solely to personalize your
        shopping experience on Versipel.
      </p>
      <p style={{ fontFamily: "DM Sans", paddingBottom: 40 }}>
       Versipel is not affiliated with any of the brands we feature. Our recommendations are made with your best interests in mind.
      </p>
    </div>
  );
}

export default Home;
